/**
 * @author: https://www.51xuediannao.com/
 * @update: 2021-09-23 12:02
 */
const _CONFIG = Object.assign({
    "debug": true,
    "host": process.env.VUE_APP_HOST,
    "apiPath": process.env.VUE_APP_API,
    "api":{
        "www":process.env.VUE_APP_API+"/www",
        "boss":process.env.VUE_APP_API+"/boss",
        "apiThirdPath":"http://123.57.104.245:10221",
        "apiMg":"https://admintest001.admin.maigertest.cn:1800",
    },
    "apiOSSPath":"https://zhiyefuliwang.oss-cn-beijing.aliyuncs.com/",
    "siteUrl": 'http://xx.xxx.com',
    "cdn": "https://zhiyefuliwang.oss-cn-beijing.aliyuncs.com/",
    "rootPath": "",
    "siteName": "后台管理系统",
    "systemFullName": "",
    "shortName": "",
    "slogan":"",
    "column": []
}, {});

export {_CONFIG}