<template>
  <div class="site-header">
    <div class="start">
      <span class="unit-name">{{ systemConfig.name }}</span>
      <span class="em" style="vertical-align: bottom">【{{env}}】</span>
    </div>
    <div class="end">
      <span class="user-name">HI，{{ userInfo.u_realname||userInfo.u_nickname||userInfo.u_username }}</span>
      <span class="switch-user" @click="logout">退出用户</span>
    </div>
    <Dialog :dialogVisible1.sync="dialogVisible" content="亲，确定要退出登陆吗?" @sureClick="btnClick"></Dialog>
  </div>
</template>

<script>
import {_data, _methods, util} from '../../common/public.js';
import Dialog from '@/components/dialog/dialog.vue';

export default {
    components:{
        Dialog
    },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ..._data,
      systemConfig:{},
      userInfo: {u_realname: '',u_nickname:'',u_username:''},
      env:"",
        dialogVisible:false,
    }
  },
  created() {
    this.systemConfig = util.getLocalStorage('systemConfig',{})
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log('this.userInfo',this.userInfo,this.systemConfig)

    this.getEnv()
  },
  methods: {
    ..._methods,
    logout() {
      this.dialogVisible = true;
    },
    getEnv(){
      if(location.port===""||location.port==="80"){
        if(location.host==="fl-boss.effmaster.com"){
          this.env = "正式环境"
        }else{
          this.env = "测试环境"
        }
      }else{
        this.env = "本地环境"
      }
    },
      /**
       * 弹出框确认按钮
       * */
      btnClick(option){
          this.dialogVisible = false;
          if (option){
              localStorage.setItem('TOKEN', '');
              localStorage.setItem('userInfo', '');
              this.$router.push('Login');
          }

      },
  },
}
</script>

<style lang="scss" scoped>
.site-header {
  display: flex;
  background: #fff;
  height: 70px;
  margin-bottom: 16px;
}

.site-header-placeholder {
  height: 70px;
  margin-bottom: 16px;
}

.site-header .start {
  width: 50%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.site-header .start .unit-name {
  font-size: 20px;
}

.site-header .end {
  flex: 1;
  text-align: right;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.site-header .end span {
  margin-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}

.site-header .switch-user {
  cursor: pointer;
}

.site-header .switch-user:hover {
}
</style>
