/**
 * Created by Administrator on 2020/11/26.
 */
import {$http} from '../common/axios.js';
import {ServicesGlobal} from './services.js';

class ClassifyServices extends ServicesGlobal {
    constructor(props) {
        super(props);
    }

    getClassify(data={}) {
        return $http.get(`{boss}/api.php?url=classify/show`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    detail(id,data={}) {
        return $http.get(`{boss}/api.php?url=classify/details&Id=${id}`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    delClassify(id,data={}){
        let params = this.postParams(data);
        return $http.delete(`{boss}/api.php?url=classify/delete&Id=${id}`, {data:params})
            .then(function (response) {
                return response.data;
            });
    }
    delClassifyRuan(id,data={}){
        let params = this.postParams(data);
        return $http.delete(`{boss}/api.php?url=classify/deleteRuan&Id=${id}`, {data:params})
            .then(function (response) {
                return response.data;
            });
    }

    addClassify(data={}){
        let params = this.postParams(data);
        return $http.post(`{boss}/api.php?url=classify/create`, params)
            .then(function (response) {
                return response.data;
            });
    }

    editClassify(id,data={}){
        let params = this.postParams(data);
        return $http.put(`{boss}/api.php?url=classify/update&Id=${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }


    statusChange(data={}){
        let params = this.postParams(data);
        return $http.put(`{boss}/api.php?url=classify/statusChange`, params)
            .then(function (response) {
                return response.data;
            });
    }


}


export default (new ClassifyServices())
