<template>
    <div class="dialog-box">
        <el-dialog
                v-model="dialogVisible"
                :title="title"
                width="30%"
                :before-close="handleClose"
        >
            <span>{{content}}</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">{{cancelTitle}}</el-button>
        <el-button type="primary" @click="sureBtn">
          {{sureTitle}}
        </el-button>
      </span>
            </template>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: 'Dialog',
        props: {
            dialogVisible1:{
                type:Boolean,
                default:false,
            },
            title:{
                type:String,
                default:'提示'
            },
            content:{
                type:String,
                default:'内容'
            },
            cancelTitle:{
                type:String,
                default:'取 消'
            },
            sureTitle:{
                type:String,
                default:'确 定'
            }
        },
        data() {
            return {
                dialogVisible:false
            }
        },
        watch:{
            dialogVisible1(newVal, oldVal){
                this.dialogVisible = newVal
                console.log("ssss")
            },
        },
        mounted() {},
        created() {

        },
        methods: {
            /**
             * 点击叉号事件
             * */
            handleClose(){
                this.dialogVisible = false;
                this.$emit('sureClick',false);
            },
            /**
             * 确定按钮
             * */
            sureBtn(){
                this.dialogVisible = false;
                this.$emit('sureClick',true);
            }
        }
    }
</script>
<style lang="scss" scoped>

    .dialog-box ::v-deep .el-dialog__title{
        display: flex;
    }
    .dialog-box ::v-deep .el-dialog__body{
        display: flex;
        flex-wrap: wrap;
    }
    .dialog-footer button:first-child {
        margin-right: 10px;
    }
</style>