import { createRouter, createWebHashHistory } from 'vue-router'
import open from '@/layout/open'
import screen from '@/layout/screen'
import platform from '@/layout/platform'
import classify from "../services/classify";

const openRoutes = [
    {
        path: '/login',
        name: 'login',
        redirect: '/login',
        component: open,
        meta: {title: '登录', classify: 'login', icon: 'login', affix: true, isShow: 0, table:''},
        children: [
            {
                path: '/login',
                component: () => import('@/views/login/index'),
                name: 'login',
                meta: {title: '登录-蓝风铃', classify: 'login', icon: 'login', affix: true, isShow: 0, table:''}
            }
        ]
    },
    {
        path: '/regist',
        name: 'regist',
        redirect: '/regist',
        component: open,
        meta: {title: '注册', classify: 'regist', icon: 'regist', affix: true, isShow: 0, table:''},
        children: [
            {
                path: '/regist',
                component: () => import('@/views/regist/index'),
                name: 'regist',
                meta: {title: '注册-蓝风铃', classify: 'regist', icon: 'regist', affix: true, isShow: 0, table:''}
            }
        ]
    },
    {
        path: '/findpwd',
        name: 'findpwd',
        redirect: '/findpwd',
        component: open,
        meta: {title: '找回密码', classify: 'findpwd', icon: 'findpwd', affix: true, isShow: 0, table:''},
        children: [
            {
                path: '/findpwd',
                component: () => import('@/views/findpwd/index'),
                name: 'findpwd',
                meta: {title: '找回密码-蓝风铃', classify: 'findpwd', icon: 'findpwd', affix: true, isShow: 0, table:''}
            }
        ]
    },{
        path: '/attendance',
        name: 'attendance',
        redirect: '/attendance',
        component: screen,
        meta: {title: '出勤', classify: 'attendance', icon: 'attendance', affix: true, isShow: 0, table:''},
        children: [
            {
                path: '/attendance',
                component: () => import('@/views/attendance/index'),
                name: 'attendance',
                meta: {title: '出勤-蓝风铃', classify: 'attendance', icon: 'attendance', affix: true, isShow: 0, table:''}
            },
            {
                path: '/attendance/student',
                component: () => import('@/views/attendance/student'),
                name: 'attendanceStudent',
                meta: {title: '出勤-蓝风铃-学生', classify: 'attendance', icon: 'attendance', affix: true, isShow: 0, table:''}
            },
            {
                path: '/attendanceT',
                component: () => import('@/views/attendance/indexT'),
                name: 'attendanceT',
                meta: {title: '出勤-蓝风铃', classify: 'attendance', icon: 'attendance', affix: true, isShow: 0, table:''}
            },
            {
                path: '/attendance/teacher',
                component: () => import('@/views/attendance/teacher'),
                name: 'attendanceTeacher',
                meta: {title: '出勤-蓝风铃-教师', classify: 'attendance', icon: 'attendance', affix: true, isShow: 0, table:''}
            }
        ]
    },

]
const homeRoutes = filterByKey([
    {
        path: '/',
        component: platform,
        redirect: '/home',
        name: "home",
        meta: {title: '首页', classify: 'home', icon: 'home', affix: true, level: 5, isShow: 1,authClassify:"home", table:''},
        children: [
            {
                path: '/home',
                component: () => import('@/views/home/index'),
                name: 'homeIndex',
                meta: {title: '首页', classify: 'home', icon: 'home', affix: true, level: 5, isShow: 1,authClassify:"home", table:''}
            }
        ]
    },
])

const classifyRoutes = filterByKey([
    {
        path: '/classify',
        component: platform,
        redirect: '/classify/index',
        name: "classify",
        meta: {title: '分类管理', classify: 'classify', icon: 'classify', affix: true, level: 2, role: 2, isShow: 1,authClassify:"classify"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/classify/index'),
                name: 'classifyIndex',
                meta: {title: '分类管理', classify: 'classify', icon: 'classify', affix: true, level: 2, role: 2, isShow: 1,authClassify:"classify"}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/classify/detail'),
                name: 'classifyDetail',
                meta: {title: '分类管理详情', classify: 'classify', icon: 'classify', affix: true, level: 2, role: 2, isShow: 1,authClassify:"classify"}
            }
        ])
    },
])

const classRoutes = filterByKey([
    {
        path: '/class',
        component: platform,
        redirect: '/class/index',
        name: "class",
        meta: {title: '班级管理', classify: 'class', icon: 'class', affix: true, level: 2, role: 2, isShow: 1,authClassify:"class"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/class/index'),
                name: 'classIndex',
                meta: {title: '班级管理', classify: 'class', icon: 'class', affix: true, level: 2, role: 2, isShow: 1,authClassify:"class"}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/class/detail'),
                name: 'classDetail',
                meta: {title: '班级管理详情', classify: 'class', icon: 'class', affix: true, level: 2, role: 2, isShow: 1,authClassify:"class"}
            }
        ])
    },
])


const teacherRoutes = filterByKey([
    {
        path: '/teacher',
        component: platform,
        redirect: '/teacher/index',
        name: "teacher",
        meta: {title: '教师管理', classify: 'teacher', icon: 'teacher', affix: true, level: 2, role: 2, isShow: 1,authClassify:"teacher"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/teacher/index'),
                name: 'teacherIndex',
                meta: {title: '教师管理', classify: 'teacher', icon: 'teacher', affix: true, level: 2, role: 2, isShow: 1,authClassify:"teacher",}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/teacher/detail'),
                name: 'teacherDetail',
                meta: {title: '教室管理详情', classify: 'teacher', icon: 'teacher', affix: true, level: 2, role: 2, isShow: 1,authClassify:"teacher"}
            }
        ])
    },
])

const studentRoutes = filterByKey([
    {
        path: '/student',
        component: platform,
        redirect: '/student/index',
        name: "student",
        meta: {title: '学生管理', classify: 'student', icon: 'student', affix: true, level: 2, role: 2, isShow: 1,authClassify:"student"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/student/index'),
                name: 'studentIndex',
                meta: {title: '学生管理', classify: 'student', icon: 'student', affix: true, level: 2, role: 2, isShow: 1,authClassify:"student"}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/student/detail'),
                name: 'studentDetail',
                meta: {title: '学生管理详情', classify: 'student', icon: 'student', affix: true, level: 2, role: 2, isShow: 1,authClassify:"student"}
            }
        ])
    },
])

const signTRoutes = filterByKey([
    {
        path: '/signT',
        component: platform,
        redirect: '/signT/index',
        name: "signT",
        meta: {title: '签到管理(教师)', classify: 'signT', icon: 'signT', affix: true, level: 2, role: 2, isShow: 1,authClassify:"signT"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/signT/index'),
                name: 'signTIndex',
                meta: {title: '签到管理(教师)', classify: 'signT', icon: 'signT', affix: true, level: 2, role: 2, isShow: 1,authClassify:"signT"}
            },
        ])
    },
    {
        path: '/qingJiaT',
        component: platform,
        redirect: '/qingJiaT/index',
        name: "qingJiaT",
        meta: {title: '请假管理(教师)', classify: 'qingJiaT', icon: 'qingJiaT', affix: true, level: 2, role: 2, isShow: 1,authClassify:"qingJiaT"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/qingJiaT/index'),
                name: 'qingJiaTIndex',
                meta: {title: '请假管理(教师)', classify: 'qingJiaT', icon: 'qingJiaT', affix: true, level: 2, role: 2, isShow: 1,authClassify:"qingJiaT"}
            },
        ])
    },
])

const signRoutes = filterByKey([
    {
        path: '/sign',
        component: platform,
        redirect: '/sign/index',
        name: "sign",
        meta: {title: '签到管理(学生)', classify: 'sign', icon: 'sign', affix: true, level: 2, role: 2, isShow: 1,authClassify:"sign"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/sign/index'),
                name: 'signIndex',
                meta: {title: '签到管理(学生)', classify: 'sign', icon: 'sign', affix: true, level: 2, role: 2, isShow: 1,authClassify:"sign"}
            },
        ])
    },
    {
        path: '/qingJia',
        component: platform,
        redirect: '/qingJia/index',
        name: "qingJia",
        meta: {title: '请假管理(学生)', classify: 'qingJia', icon: 'qingJia', affix: true, level: 2, role: 2, isShow: 1,authClassify:"qingJia"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/qingJia/index'),
                name: 'qingJiaIndex',
                meta: {title: '请假管理(学生)', classify: 'qingJia', icon: 'qingJia', affix: true, level: 2, role: 2, isShow: 1,authClassify:"qingJia"}
            },
        ])
    },
])

const noticeRoutes = filterByKey([
    {
        path: '/notice',
        component: platform,
        redirect: '/notice/index',
        name: "notice",
        meta: {
            title: '通知管理',
            classify: 'notice',
            icon: 'notice',
            affix: true,
            role: 4,
            level: 5,
            isShow: 1,
            authClassify: "notice"
        },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/notice/index'),
                name: 'noticeIndex',
                meta: {
                    title: '通知管理',
                    classify: 'notice',
                    icon: 'notice',
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 1,
                    authClassify: "notice"
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/notice/detail'),
                name: 'noticeTable',
                meta: {
                    title: '通知管理',
                    classify: 'notice',
                    icon: 'notice',
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 1,
                    authClassify: "notice"
                }
            }
        ])
    },
])

const printRoutes = filterByKey([
    {
        path: '/print',
        component: platform,
        redirect: '/print',
        name: "print",
        meta: {title: '数据打印', classify: 'print', icon: 'print', affix: true, level: 5, isShow: 1,authClassify:"print", table:''},
        children: [
            {
                path: '/print',
                component: () => import('@/views/print/index'),
                name: 'printIndex',
                meta: {title: '打印', classify: 'print', icon: 'print', affix: true, level: 5, isShow: 1,authClassify:"print", table:''}
            },
            {
                path: '/upload',
                component: () => import('@/views/print/upload'),
                name: 'uploadIndex',
                meta: {title: '导入数据', classify: 'print', icon: 'print', affix: true, level: 5, isShow: 1,authClassify:"print", table:''}
            }
        ]
    },
])


const systemRoutes = filterByKey([
    {
        path: '/boss',
        component: platform,
        redirect: '/boss/index',
        name: "boss",
        meta: {title: '平台管理员', classify: 'boss', icon: 'boss', affix: true, level: 2, role: 2, isShow: 1,authClassify:"boss"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/boss/index'),
                name: 'bossIndex',
                meta: {title: '平台管理员', classify: 'boss', icon: 'boss', affix: true, level: 2, role: 2, isShow: 1,authClassify:"boss"}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/boss/detail'),
                name: 'bossDetail',
                meta: {title: '平台管理员详情', classify: 'boss', icon: 'boss', affix: true, level: 2, role: 2, isShow: 1,authClassify:"boss"}
            }
        ])
    },
    {
        path: '/auth',
        component: platform,
        redirect: '/auth/index/0',
        name: "auth",
        meta: {title: '授权管理', classify: 'auth', icon: 'auth', affix: true, level: 2, role: 2, isShow: 1,authClassify:"auth"},
        children: filterByKey([
            {
                path: 'index/:id',
                component: () => import('@/views/auth/index'),
                name: 'authIndex',
                meta: {title: '授权管理', classify: 'auth', icon: 'auth', affix: true, level: 2, role: 2, isShow: 1,authClassify:"auth"}
            },
            {
                path: 'options',
                component: () => import('@/views/auth/options'),
                name: 'authOptions',
                meta: {title: '分类选项管理', classify: 'auth', icon: 'auth', affix: true, level: 2, role: 2, isShow: 1,authClassify:"auth"}
            }
        ])
    },
    {
        path: '/system',
        component: platform,
        redirect: '/system/index',
        name: "system",
        meta: {title: '系统配置', classify: 'system', icon: 'system', affix: true, level: 2, role: 2, isShow: 1,authClassify:"system"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/system/index'),
                name: 'systemIndex',
                meta: {title: '系统配置', classify: 'system', icon: 'system', affix: true, level: 2, role: 2, isShow: 1,authClassify:"system"}
            },
        ])
    }
])

const constantRoutes = [
    ...openRoutes,
    ...homeRoutes,
    ...classifyRoutes,
    ...classRoutes,
    ...teacherRoutes,
    ...studentRoutes,
    ...signTRoutes,
    ...signRoutes,
    ...noticeRoutes,
    ...printRoutes,
    ...systemRoutes
]

const router = createRouter({
    history: createWebHashHistory(),
    routes:constantRoutes
})

//TODO: 先实现下一级的过滤，
function filterByKey(arrayObj) {
    let index = arrayObj.findIndex((item) => {
        return item["meta"]["isShow"] === 0
    })
    if (index > -1) {
        arrayObj.splice(index, 1);
    }
    return arrayObj
}


export {
    homeRoutes,
    classifyRoutes,
    classRoutes,
    teacherRoutes,
    studentRoutes,
    signTRoutes,
    signRoutes,
    noticeRoutes,
    printRoutes,
    systemRoutes,
    router
}
